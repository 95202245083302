const originalFetch = window.fetch;

window.fetch = async (...args) => {
    const response = await originalFetch(...args);

    if (response.status === 401) {
        window.location.reload();
    }

    return response;
};