const baseUrl = "https://api.idrivers.net/api/admin";

export function getUrl(route) {
    return `${baseUrl}${route}`;
}

export async function getCodeAsync(username){
    const formData = new FormData();
    formData.append('username', username);

    const url = getUrl('/authorize/GetCode');
    const resp = await fetch(url, {
        method: 'POST',
        body: formData
    });

    var respText = await resp.text();
    if(resp.status === 456){
        throw respText;
    } else if(!resp.ok) {
        console.log("error:", respText);
        throw "Something went wrong.";
    } else {
        return respText;
    }
}

export async function checkCodeAsync(username, code){
    const formData = new FormData();
    formData.append('username', username);
    formData.append('code', code);

    const url = getUrl('/authorize/CheckCode');
    const resp = await fetch(url, {
        method: 'POST',
        body: formData
    });

    var respText = await resp.text();
    if(resp.status === 456){
        throw respText;
    } else if(!resp.ok) {
        console.log("error:", respText);
        throw "Something went wrong.";
    } else {
        return respText;
    }
}

export async function signInAsync(username, password){
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const url = getUrl('/authorize');
    const resp = await fetch(url, {
        method: 'POST',
        body: formData
    });

    var respText = await resp.text();
    if(resp.status === 456){
        throw respText;
    } else if(!resp.ok) {
        console.log("error:", respText);
        throw "Something went wrong.";
    } else {
        return respText;
    }
}

export async function getDriversAsync(key, status){
    
    const url = getUrl(`/drivers?status=${status}`);

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function getXCheckableServerRouterGroupings(key){
    
    const url = getUrl(`/servers/XCheckableServerRouterGroupings`);

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function getDriverIdsWuthServersAsync(key){
    
    const url = getUrl(`/driveridswithservers`);

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function PostDriverAsync(key, driver){
    
    const url = getUrl('/drivers');

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(driver)
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    } else {
        return await resp.json();
    }
}

export async function GetEmptyDriverAsync(key){
    const url = getUrl('/drivers/empty');

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function GetSharedServerKeysAsync(key){
    const url = getUrl('/sharedServerKeys');

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function GetFilesAsync(key) {
    const url = getUrl('/files');

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}


export async function SendMessageToAllAsync(key, data){
    const url = getUrl('/message/all');

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    } else {
        return await resp.text();
    }
}

export async function GetServersAsync(key){
    const url = getUrl('/servers');

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}

export async function GetDriverServersAsync(key){
    const url = getUrl('/driverServers');
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    const json = await resp.json();
    return json;
}


export async function DeleteDriverServerBindingAsync(key, driverId){
    const url = getUrl(`/removeDriverServer/${driverId}`);

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }
}


export async function CreateMissingBindingsAsync(key){
    const url = getUrl(`/populateDriverServers`);

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return resp.text();
}



/* servers */

export async function getRoutersAsync(key){
    
    const url = getUrl(`/routers`);

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    const json = await resp.json();
    return json;
}

export async function getServersAsync(key){
    
    const url = getUrl(`/servers`);

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    const json = await resp.json();

    return json.sort((a, b) => a.index - b.index);
}

export async function PostServerAsync(key, driverConfig){
    
    const url = getUrl('/servers');

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(driverConfig)
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    } else {
        return await resp.json();
    }
}

export async function GetEmptyServerAsync(key){
    const url = getUrl('/servers/empty');

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    const json = await resp.json();
    return json;
}


export async function PostSshCommandAsync(key, request){
    
    const url = getUrl('/servers/ssh');

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    } else {
        return await resp.json();
    }
}

async function GetTextContentAsync(key, url){

    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key
        }
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    const text = await resp.text();
    return text;
}

export async function GetFileContentAsync(key, fileKey){
    const url = getUrl(`/servers/file/${fileKey}`);
    return await GetTextContentAsync(key, url);
}

export async function GetIptablesContentAsync(key, routerId){
    let url = getUrl(`/servers/iptables`);
    if(routerId){
        url = `${url}?routerId=${routerId}`;
    }
    return await GetTextContentAsync(key, url);
}

export async function GetRouterServersHealth(key, routerId){
    let url = getUrl(`/aws/checkrouterservers/${routerId}`);
   
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}

export async function GetServerAWSProperties(key, routerId, serverIndex){
    let url = getUrl(`/aws/getserverproperties/${routerId}/${serverIndex}`);
   
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}

export async function UpdateAwsStatuses(key){
    let url = getUrl(`/aws/updateStatuses`);
   
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}

export async function GetAwsInstanceStatus(key, instanceId, routerId){
    let url = getUrl(`/aws/instanceStatus?instanceId=${instanceId}&routerId=${routerId}`);
   
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}


export async function SetAwsInstanceStatus(key, instanceId, routerId, shouldBeRunning){
    let url = getUrl(`/aws/startStopInstance?instanceId=${instanceId}&routerId=${routerId}&shouldBeRunning=${shouldBeRunning}`);
   
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}

export async function PostFileContentAsync(key, fileKey, content){
    const url = getUrl(`/servers/file/${fileKey}`);

    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: content
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }
}

export async function ExecSshAsync(key, content){
    const url = getUrl(`/servers/ssh`);
    console.log(content);
    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}


export async function CalculatePaymentAsync(key, driverId, payAmount){
    const url = getUrl(`/payments/prepare/${driverId}/${payAmount}`);
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}



export async function ApprovePaymentAsync(key, paymentId){
    const url = getUrl(`/payments/register/${paymentId}`);
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}



export async function GetPaymentHistoryAsync(key, driverId){
    const url = getUrl(`/payments/history/${driverId}`);
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}


export async function GetPaymentHistoryAllAsync(key, from, to){
    const url = getUrl(`/payments/history/all?from=${from}&to=${to}`);
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.json();
}

export async function GetTempLinkAsync(key, fileKey, fileName){
    const url = getUrl(`/file/templink/${Date.now()}/${fileKey}/${fileName}`);
    
    const resp = await fetch(url, {
        method: 'GET',
        headers: {
            "authorize": key,
            "Content-Type": "application/json"
        },
    });

    if(resp.status === 456){
        throw await resp.text();
    } else if(!resp.ok) {
        console.log("error:", await resp.text());
        throw "Something went wrong.";
    }

    return await resp.text();
}